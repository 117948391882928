import React from 'react';
import { Element } from 'react-scroll';
import styled from 'styled-components';

const Content = styled.div`
  @keyframes BgTransition {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  height: 70vh;
  margin-bottom: 100px;
  animation-name: BgTransition;
  animation-duration: 4s;
  transition: background-size 1s;
  background-image: url(img/treesIntro.png);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: bottom;
`
const GreetingContainer = styled.div`
  padding-top: 0px;
  z-index: -1;
`

const TextContainer = styled.div`
  position: relative;
  top: 7em;
  transition: top 2s;

  @media (min-width: 600px) {
    top: 12em;
  }
`

const StyledH1 = styled.h1`
  color: ${props => props.theme.primaryColor};
  margin: 0;
  font-size: 4rem;
  text-align: center;  
  transition: font-size 2s;

  @media (min-width: 600px) {
    font-size: 10rem;
  }
`

const StyledH3 = styled.h3`  
  color: ${props => props.theme.secondaryColor};
  margin: -40px 0 0 100px;
  font-size: 2rem;
  text-align: center;  
  transition: font-size margin 2s;

  @media (min-width: 600px) {
    font-size: 4rem;
    margin: -70px 0 0 300px;
  }
`

const Intro = () => {
  return (
    <Element name='intro'>
      <Content>
        <GreetingContainer>
          <TextContainer>
            <StyledH1>Velkomin</StyledH1>
            <StyledH3>til Fjólunnar</StyledH3>
          </TextContainer>
        </GreetingContainer>
      </Content>
    </Element>
  );
}

export default Intro;
