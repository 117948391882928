import React, { useState, useRef } from 'react';
import { array } from 'prop-types';
import styled from 'styled-components';

import { useOnClickOutside } from '../../hooks';

import Burger from './Burger';
import Menu from './Menu';
import Logo from './Logo';
import NavLink from './NavLink';

const NavContainer = styled.div`
  position: fixed;
  top: 0;
  padding-top: 20px;
  width: 100%;
  display: flex;
  border: none;
  background-color: #f1defe;
  z-index: 10;
  -webkit-box-shadow: 0px 10px 30px 0px #F1DEFE; 
  box-shadow: 0px 10px 30px 0px #F1DEFE;
`;

const StyledNavigation = styled.div`
  float: right;
  width: inherit;
  display: block;

  @media (max-width: ${props => props.theme.burgerBreak}) {
    display: none;
  }
`;

const StyledList = styled.ul`
  list-style: none;
  float: right;
  display: flex;
  margin-top: 45px;

  @media (max-width: ${props => props.theme.burgerBreak}) {
    display: block;
    width: 300px;
  }
`;

const StyledP = styled.p`
  margin: 20px 40px 0 40px;
  border-bottom: 1px solid;
  font-size: 1.2em;
`

const LinkList = (links, setOpen) => {
  return(
    <StyledList>
      {links !== null ? 
        links.map((link, index) => 
          <NavLink 
            key={`link-${index}`} 
            to={link.to} 
            name={link.name} 
            setOpen={setOpen} 
          />) :
        null
      }
    </StyledList>
  );
}

const NavBar = (props) => {
  const [open, setOpen] = useState(false);
  const node = useRef();
  
  useOnClickOutside(node, () => setOpen(false));

  return (
    <NavContainer>
      <Logo />
      <StyledNavigation>
        {LinkList(props.links, setOpen)}
      </StyledNavigation>  
      <div ref={node}>
        <Burger open={open} setOpen={setOpen} />
        <Menu open={open} setOpen={setOpen}>
          <StyledP>Fjólan - Valmynd</StyledP>
          {LinkList(props.links, setOpen)}
        </Menu>
      </div>
    </NavContainer>
  );
};

NavBar.propTypes = {
  links: array.isRequired,
};

export default NavBar;
