export const theme = {
  textColor: '#84009A',
  primaryColor: '#7D0DD6',
  secondaryColor: '#B256B3',
  menuShadowColor: '#E4CFF5',
  cardBackgroundColor: '#f1d4fd',
  cardMinHeight: '500px',
  cardWidth: '70%',
  cardWidthMobile: '80%',
  cardColor: '#545454',
  burgerBreak: '1250px',
}
