import { createGlobalStyle } from 'styled-components';
import Font from './fonts/CormorantGaramond-Regular.ttf';

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Cormorant Garamond';
    src: url(${Font}) format('woff2');        
  }

  body {
    background: rgb(252,247,254);
    background: radial-gradient(circle, rgba(252,247,254,1) 0%, rgba(239,218,254,1) 100%);    
    color: ${({ theme }) => theme.textColor}; 
    font-size: 1.2em;
    font-family: 'Cormorant Garamond', serif;
    line-height: 1.7;    
    margin: 0;
  }
`
