import React from 'react';
import { func, string } from 'prop-types';
import { Link } from 'react-scroll'
import styled from 'styled-components';

export const StyledListItem = styled.li`
  color: ${props => props.theme.primaryColor};
  cursor: pointer;
  margin: 0 0 30px 0;
  font-size: 1.5rem;
  line-height: 2;

  &:hover {
    border-bottom: none;
  }

  @media (min-width: 600px) {
    margin: 0 50px 0 0;

    &:hover {
      border-bottom: 2px solid ${props => props.theme.primaryColor};
    }
  }
`;

const NavLink = (props) => {
  return (
    <StyledListItem>
      <Link 
        to={props.to} 
        spy={true}
        isDynamic={true}
        smooth={true} 
        duration={1500}
        offset={-200}
        onClick={() => props.setOpen(false)}
      >
        {props.name}
      </Link>
    </StyledListItem>
  )
}

NavLink.propTypes = {
  to: string.isRequired,
  name: string.isRequired,
  setOpen: func,
}

export default NavLink;