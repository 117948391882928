import React from 'react';
import { bool, func } from 'prop-types';
import styled from 'styled-components';

export const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  width: 2rem;
  height: ${props => props.open ? '4rem' : '2rem'};
  background: transparent;
  border: ${props => props.open ? 'none' : `1px solid ${props.theme.primaryColor}`};
  opacity: 0.5;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;
  margin: 40px 50px 0 0;
  z-index: 10;
  position: absolute;
  right: 0;

  @media (min-width: ${props => props.theme.burgerBreak}) {
    display: none;
  }

  &:focus {
    outline: none;
  }

  div {
    width: 1.3rem;
    height: 0.14rem;
    background: ${props => props.theme.primaryColor};
    transition: all 0.3s linear;
    margin: 3px 0 3px 0;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${props => props.open ? 'rotate(25deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${props => props.open ? '0' : '1'};
      transform: ${props => props.open ? 'translateX(0px)' : 'translateX(20)'};
    }

    :nth-child(3) {
      transform: ${props => props.open ? 'rotate(-25deg)' : 'rotate(0)'};
    }
  }
`;

const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}

Burger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default Burger;