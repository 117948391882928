import React from 'react';
import styled from 'styled-components';

import Intro from './Intro';
import Section from './Section';

const SectionContainer = styled.div`
  margin: 140px 0 100px 0;
`

const Copyright = styled.p`
  text-align: center;
`

const medferdir = { to: 'medferdir', name: 'Meðferðir og fundir' };
const orkustodvar = { to: 'orkustodvar', name: 'Orkustöðvarnar' };
const spaekmaeli = { to: 'spakmaeli', name: 'Spakmæli' };
const gjafakort = { to: 'gjafakort', name: 'Gjafakort' };
const fjolan = { to: 'fjolan', name: 'Fjólan' };

export const getLinks = () => {
  return [medferdir, orkustodvar, spaekmaeli, gjafakort, fjolan];
}

const Page = (props) => {
  const { scrollPosition } = props;

  return (
    <SectionContainer>
      <Intro />
      <Section shouldShow={scrollPosition > 300} navigation={medferdir} />
      <Section shouldShow={true} navigation={orkustodvar} />
      <Section shouldShow={true} navigation={spaekmaeli} />
      <Section shouldShow={true} navigation={gjafakort} />
      <Section shouldShow={true} navigation={fjolan} />
      <Copyright>© 2023 Fjólan</Copyright>
    </SectionContainer>
  );
};

export default Page;
