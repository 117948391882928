import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll'

export const LogoContainer = styled(Link)`
  display: flex;
  width: 40%;
  cursor: pointer;
`

export const LogoImage = styled.img`
  padding: 10px 0 10px 15px;  
  height: 100px;
  margin-left: 10px;
  content:url(img/fjolanLogo.png);
`

export const LogoText = styled.div`
  margin: 0px 20px 30px 20px;
  line-height: 1.2;

  @media (max-width: 400px) {
    display: none;
  }

  @media (max-width: 1000px) {
    width: 100%;
  }
`
export const StyledH1 = styled.h1`
  color: ${props => props.theme.primaryColor};
  margin-bottom: -12px;
`

export const StyledH4 = styled.h4`
  color: ${props => props.theme.secondaryColor};
  margin: 2px 0 0 10px;
`

const Logo = () => {
  return (
    <LogoContainer
      to='intro' 
      spy={true}
      isDynamic={true}
      smooth={true} 
      duration={1500}
      offset={-200}
    >
      <LogoImage />
      <LogoText>
        <StyledH1>Fjólan</StyledH1>
        <StyledH4>Heilunarkjarni</StyledH4>
      </LogoText>
    </LogoContainer>
  )
}

export default Logo;
