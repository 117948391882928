import React from 'react';
import { bool } from 'prop-types';
import styled from 'styled-components';

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.cardBackgroundColor};
  height: 100vh;
  padding: 100px 30px 30px 30px;
  position: absolute;
  top: 0px;
  right: 0px;
  box-shadow: 0px 0px 20px 0px ${props => props.theme.menuShadowColor};
  transition: transform 0.3s ease-in-out;
  transform: ${props => props.open ? 'translateX(0%)' : 'translateX(100%)'};

  @media (max-width: 300px) {
    width: 100%;
  }
`;

const Menu = (props) => {
  return (
    <StyledMenu open={props.open}>
      {props.children}
    </StyledMenu>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu;