import React from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './global';
import { theme } from './theme';
import NavBar from './components/Header/NavBar';
import Page, { getLinks } from './components/Page';
import { withTheme } from './provider/ThemeProvider';

function App(props) {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
				<NavBar links={getLinks()} />
				<Page scrollPosition={props.scrollPosition} />
      </>
    </ThemeProvider>
  );
}

export default withTheme(App);
