import React from 'react';
import { bool } from 'prop-types';
import styled, { css } from 'styled-components';
import { Element } from 'react-scroll'
import ModalImage from "react-modal-image";
import PageContent from '../content/PageContent.json';

const mobileBreak = '650px';
const areaBorder = '1px solid #deb5ff';

const StyledSection = styled.div`
  opacity: 0;
  transition: opacity 1s ease-out;

  ${ props => props.shouldShow && css`
    opacity: 1;
    transition: opacity 1s ease-in;
  `};
`

const StyledElement = styled(Element)`
  margin: 0px auto 0 auto;
  width: ${props => props.theme.cardWidthMobile};

  @media (min-width: ${mobileBreak}) {
    width: ${props => props.theme.cardWidth};
  }
`

const StyledCard = styled.div`
  background: ${props => props.theme.cardBackgroundColor};
  background-image: url(img/cardBackground.png);
  border-radius: 10px;
  padding: 20px;
  color: ${props => props.theme.cardColor};
  margin: 150px 0;
`

const StyledH1 = styled.h1`
  font-size: 1.5rem;
  transition: font-size 2s;
  margin: 40px 0 40px 0px;
  color: ${props => props.theme.primaryColor};
  text-align: center;

  @media (min-width: ${mobileBreak}) {
    font-size: 3rem;
  }
`

const StyledH2 = styled.h2`
  color: ${props => props.theme.primaryColor};
  font-size: 1.3rem;

  @media (min-width: ${mobileBreak}) {
    font-size: 1.7rem;
  }
`

const Container = styled.div`
  display: block;  

  @media (min-width: ${mobileBreak}) {
    display: grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-template-areas:
      'upperCenter upperCenter'
      'left right'
      'left2 right2'
      'left3 right3'
      'lowerCenter1 lowerCenter1'
      'lowerCenter2 lowerCenter2'
      'lowerCenter3 lowerCenter3'
      'lowerCenter4 lowerCenter4'
      'lowerCenter5 lowerCenter5';
  }  
`

const Area = styled.div`
  grid-area: ${props => props.layout};
  padding: 0 20px 20px 20px;

  ${({layout, borderRight}) => (layout === 'left' || layout === 'left2') && borderRight === true &&`
    border-right: none;
    
    @media (min-width: ${mobileBreak}) {
      border-right: ${areaBorder};      
    }
  `}
`

const StyledCleanText = styled.div`
  text-align: ${props => props.textAlign};
`

const StyledParagraph = styled.p`
  text-align: ${props => props.textAlign};
`

const StyledImage = styled.img`
  border-radius: 10px;
  display: block;
  margin: 50px auto;
  width: ${props => props.width};
  max-width: 1000px;
`

const StyledModalImage = styled(ModalImage)`
  border-radius: 10px;
  display: block;
  margin: 50px auto;
  width: ${props => props.width};
  max-width: 1000px;
`

const StyledHr = styled.hr`
  border-top: ${areaBorder};
  border-bottom: none;
  border-left: none;
  border-right: none;
`

const BottomDecor = styled.div`
  display: block;

  @media (min-width: ${mobileBreak}) {
    display: block;
    background: url(img/treesDecor.png);
    height: 300px;
    margin: -300px -20px -20px -20px;
    background-repeat: no-repeat; 
  }
`

const renderContent = (pageContent) => {
  if (pageContent) {
    const { areas } = pageContent;

    return areas.map((area, areaIndex) => {
      const areaItems = area.content.map((item, itemIndex) => {
        const { type, value } = item;

        if (type === 'text') {
          return <StyledCleanText textAlign={item.textAlign ?? 'left'} key={areaIndex + itemIndex} dangerouslySetInnerHTML={ {__html: value} } />
        } else if (type === 'paragraph') {
          return <StyledParagraph textAlign={item.textAlign ?? 'left'} key={areaIndex + itemIndex} dangerouslySetInnerHTML={ {__html: value} } />
        } else if (type === 'image') {
          return <StyledImage width={item.width ?? '100%'} key={areaIndex + itemIndex} src={value} />
        } else if (type === 'modalImage') {
          return <StyledModalImage key={areaIndex + itemIndex} small={value} medium={value} />
        } else if (type === 'hr') {
          return <StyledHr />
        }

        return null;
      });
      
      return (
        <Area key={areaIndex} layout={area.layout} borderRight={area.borderRight ?? false}>          
          {area.subTitle !== undefined ? <StyledH2>{area.subTitle}</StyledH2> : null}
          {areaItems}
        </Area>
      );
    });
  }

  return null;
}

const Section = (props) => {
  const { to, name } = props.navigation;

  return (
    <StyledSection shouldShow={props.shouldShow}>
      <StyledElement name={to}>
        <StyledCard>
          <StyledH1>- {name} -</StyledH1>
          <Container>
            {renderContent(PageContent[to])}
          </Container>
          <BottomDecor />
        </StyledCard>
      </StyledElement>
    </StyledSection>
  );
}

Section.propTypes = {
  shouldShow: bool.isRequired,
}

export default Section;
